import React from 'react';
import styled from 'styled-components';
import { compose, path, equals } from 'ramda';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Introduction from '../components/freeResourcesPage/introduction';
import withCustomLayout from '../enhancers/withCustomLayout';
import withStickyHeader from '../enhancers/withStickyHeader';
import { DARK_SCHEME } from '../constants/colors';
import withScroll from '../enhancers/withScroll';
import BrochureRequest from '../components/brochureRequestPage';
import withChildRoutes from '../enhancers/withChildRoutes';
import Reports from '../components/common/reports';

// eslint-disable-next-line
class FreeResources extends React.Component {
  render() {
    const {
      data: {
        page: { introduction, sections = [] },
        freeReports: { edges: reports = [] },
      },
      scrollTo,
    } = this.props;

    const items = reports.filter(r => equals(path(['node', 'type'], r), 'free'));

    return (
      <Page>
        <Introduction
          {...introduction}
          data={sections[0]}
          scrollTo={scrollTo}
        />
        {reports.length && (
          <Reports items={items} />
        )}
      </Page>
    );
  }
}

FreeResources.propTypes = {
  data: PropTypes.shape().isRequired,
  scrollTo: PropTypes.func.isRequired,
};

const Page = styled.div`
  overflow-x: hidden;
`;

export default compose(
  withChildRoutes({
    defaultRoute: '/free-resources',
    childRoutes: [
      { path: '/free-resources/:slug', component: BrochureRequest },
    ],
  }),
  withStickyHeader(),
  withCustomLayout({ scheme: DARK_SCHEME }),
  withScroll({
    sections: {
      subscribe: [2],
    },
  }),
)(FreeResources);

// eslint-disable-next-line
export const query = graphql`
  query FreeResourcesPageQuery {
    page(url: { regex: "/free-resources/" }) {
      introduction {
        title
        subTitle
        description
        button {
          text
          url
        }
        image {
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 1280 ) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
      }
      sections {
        title
        description
      }
    }
    freeReports: allReport(sort: { fields: [ publishedAt ], order: [ DESC ] }) {
      edges {
        node {
          id
          title
          subtitle
          slug
          type
          meta {
            description
          }
          publishedAt(formatString: "MMMM YYYY")
          categories {
            id: _id
            name
            slug
          }
          cover {
            localFile {
              childImageSharp {
                sizes(maxWidth: 670) {
                  ...GatsbyImageSharpSizes_noBase64
                }
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          extra {
            keyQuestions {
              html
            }
            methodology {
              html
            }
            tableOfContents {
              html
            }
          }
          sample_report {
            url,
          }
        }
      }
    }
  }
`;
