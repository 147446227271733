import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import RoundedButton from '../buttons/roundedButton';
import { LAPTOP, TABLET, MOBILE } from '../../constants/screens';
import {
  BLACK, BLUE, DARK_GRAY, LIGHT_GRAY_1, WHITE,
} from '../../constants/colors';

const Introduction = ({
  image: { file: { localFile: { childImageSharp: { sizes } } } },
  title,
  description, button: { url, text },
  data = {},
  scrollTo,
}) => (
  <IntroductionContainer>
    <WhiteContent>
      <Title>
        {title}
      </Title>
      <Text>
        {description}
      </Text>
      <RoundedButton
        text={text}
        color={BLUE}
        tag={Link}
        to={url}
        onClick={() => { setTimeout(() => scrollTo('subscribe'), 300); }}
      />
      <ImageWrapper>
        <Image sizes={sizes} alt="Slashdata" />
      </ImageWrapper>
    </WhiteContent>
    <GrayContent>
      <SubTitle>
        {data.title}
      </SubTitle>
      <Text>
        {data.description}
      </Text>
    </GrayContent>
  </IntroductionContainer>
);

Introduction.propTypes = {
  image: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  scrollTo: PropTypes.func.isRequired,
};

const IntroductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 40px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${BLUE};
  max-width: 550px;
  margin-bottom: 30px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 26px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 0 5%;
    font-size: 26px;
    max-width: 480px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    padding: 0 5%;
    font-size: 24px;
  }
`;

const Text = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 26px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${BLACK};
  max-width: 805px;
  margin-bottom: 50px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 20px;
     margin-bottom: 35px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 0 5%;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    padding: 0 5%;
    font-size: 17px;
  }
`;

const SubTitle = styled.p`
  font-family: AvenirNextLTProBold;
  font-size: 19px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${DARK_GRAY};
  text-transform: uppercase;
  margin-bottom: 25px;
  
  @media screen and (max-width: ${LAPTOP}) {
  
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 0 5%;
    font-size: 20px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 18px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const WhiteContent = styled(Content)`
  background-color: ${WHITE};
  padding: 260px 5% 380px;
  position: relative;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 190px 5% 280px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 160px 5% 90px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    padding: 180px 5% 50px;
  }
`;

const GrayContent = styled(Content)`
  background-color: ${LIGHT_GRAY_1};
  padding: 550px 5% 145px;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 350px 5% 100px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 5%;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    padding: 50px 5% 30px;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 650px;
  max-width: 1280px;
  width: 80%;
  
  > div {
    width: 100%;
  }
  
  @media screen and (max-width: ${LAPTOP}) {
    top: 525px;
    max-width: 800px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    position: initial;
    margin-top: 75px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    margin-top: 50px;
    width: 90%;
  }
`;

export default Introduction;
